export enum MessageSearchType {
    None = 0,
    Instructor,
    Course,
    Series,
    Phone,
    Broadcast,
}

export class MessageOptions {
    file_url: string = ''
    message: string = ''
    scheduled: Date | number | null = null
}

export class MessageRecord {
    _id: string
    options: MessageOptions = new MessageOptions()
}

export enum MessageTemplateId {
    Register = 0,
    Register7Days = 1,
    FreeCourse = 2,
    Cart3Hrs = 3,
    Cart3Days = 4,
}
