












































import axios from 'axios'
import {DatePickerOptions} from 'element-ui/types/date-picker'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {MessageRecord} from '~/components/message/message-models'
import CourseSelect from '~/components/select/course-select.vue'
import InstructorSelect from '~/components/select/instructor-select.vue'
import {createRequest, getServerBaseURL} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        CourseSelect,
        InstructorSelect,
    }
})
export default class EditRecordDialog extends Vue {
    static permission = AdminPermission.SendWhatsapp

    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean
    pickerOptions: DatePickerOptions = {
        shortcuts: [{
            text: 'Today',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setDate(now.getDate())
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }, {
            text: 'Tomorrow',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                date.setDate(now.getDate() + 1)
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }]
    }
    @Prop({
        default: () => {
            return new MessageRecord()
        }
    })
    value!: MessageRecord
    fileUrl: string = ''
    message: string = ''
    scheduled: Date | number | null = null
    sending = false

    get dialogVisible() {
        return this.visible
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    @Watch('value', {deep: true})
    valueUpdated(val: MessageRecord) {
        this.fileUrl = val.options.file_url
        this.message = val.options.message
        this.scheduled = val.options.scheduled
    }

    created() {
        for (let i = 8; i <= 11; i++) {
            this.pickerOptions.shortcuts!!.push({
                text: `${i}pm`,
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    date.setHours(i + 12)
                    date.setMinutes(0)
                    date.setSeconds(0)
                    date.setMilliseconds(0)
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            })
        }
    }

    fileUrlChanged() {
        if (this.fileUrl && !this.fileUrl.startsWith('http'))
            this.fileUrl = 'https://' + this.fileUrl
    }

    async send(test = false) {
        if (!this.message && !this.fileUrl)
            return

        if (test) {
            const body = {
                message: this.message,
                test: true
            }
            if (this.fileUrl)
                body['file_url'] = this.fileUrl
            await createRequest('/bulk-messages', 'post', {}, body).send()
            this.$message.info('Test Message Sent')
            return
        }

        this.sending = true
        const body = {
            message: this.message,
            file_url: this.fileUrl
        }
        if (typeof this.scheduled !== 'number')
            body['scheduled'] = this.scheduled ? this.scheduled.getTime() : 0

        await createRequest(`/bulk-messages/${this.value._id}`, 'patch', {}, body).send()
        this.dialogVisible = false
        this.sending = false
        this.$emit('update')
    }

    async uploadFile() {
        const e: HTMLInputElement = this.$refs.fileinput as HTMLInputElement
        e.click()
    }

    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        let uploadUrl = ''
        const baseUrl = getServerBaseURL()
        if (baseUrl.startsWith('http://') || baseUrl.startsWith('https://')) {
            uploadUrl = `${baseUrl}file`
        } else {
            uploadUrl = `https://${baseUrl}file`
        }

        let form = new FormData()
        form.append('file', e.target['files'][0])

        const loading = this.$loading({
            target: '.el-dialog',
            text: 'Uploading'
        })
        const token = await this.$auth.getAccessToken()

        const res = await axios({
            method: 'post',
            url: uploadUrl,
            data: form,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        this.fileUrl = res.data['file_url']
        loading.close()
    }
}

