




























































































































































import axios from 'axios'
import {DatePickerOptions} from 'element-ui/types/date-picker'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'
import {CourseSeries} from '~/components/data-class/data-class'
import EditRecordDialog from '~/components/message/edit-record-dialog.vue'
import {MessageRecord, MessageSearchType} from '~/components/message/message-models'
import {SubjectList} from '@afterschool.dev/as-data-admin'
import PaginationMixin from '~/mixins/pagination-mixin'
import {toAdminv} from '~/utils/misc'
import {Cache, createRequest, getServerBaseURL} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        EditRecordDialog
    },
    metaInfo() {
        return {
            title: 'Whatsapp Message'
        }
    }
})
export default class MessageSender extends mixins(PaginationMixin) {
    static permission = AdminPermission.SendWhatsapp

    sendConfirmation = false
    dialogVisible = false
    editDialogVisible = false

    electives = SubjectList.elective
    MessageSearchType = MessageSearchType
    types = [
        {
            id: MessageSearchType.Phone,
            label: MessageSearchType[MessageSearchType.Phone]
        },
        {
            id: MessageSearchType.Series,
            label: MessageSearchType[MessageSearchType.Series]
        },
        {
            id: MessageSearchType.Course,
            label: MessageSearchType[MessageSearchType.Course]
        },
        {
            id: MessageSearchType.Instructor,
            label: MessageSearchType[MessageSearchType.Instructor]
        },
        {
            id: MessageSearchType.Broadcast,
            label: MessageSearchType[MessageSearchType.Broadcast]
        },
    ]
    type: MessageSearchType | null = null
    seriesList: CourseSeries[] = []

    pickerOptions: DatePickerOptions = {
        shortcuts: [{
            text: 'Today',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setDate(now.getDate())
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }, {
            text: 'Tomorrow',
            onClick(picker) {
                const date: Date = picker.value as Date || new Date()
                const now = new Date()
                date.setMonth(now.getMonth())
                date.setFullYear(now.getFullYear())
                date.setDate(now.getDate() + 1)
                picker.$emit('pick', undefined, true)
                    .$nextTick(() => {
                        picker.$emit('pick', date, true)
                    })
            }
        }]
    }

    dseOp: number[] = []

    dse: number | null = null
    dses: number[] = []
    form: string | null = null
    subject: number | null = null

    phones: string = ''
    series: number | null = null
    course: string | null = null
    instructor: string | null = null

    fileUrl: string = ''
    message: string = ''
    scheduled: Date | null = null

    count: number = 0

    sending = false

    records: any[] = []
    jobs: any[] = []

    editing: MessageRecord = new MessageRecord()
    phonePreviewDialog = false
    phonePreview = ''
    intervalPoll
    cacheData: Cache = {etag: '', value: null}

    startEdit(record: MessageRecord) {
        Object.assign(this.editing, record)
        this.editDialogVisible = true
    }

    subjectStr(sub: number) {
        return SubjectList.allId[sub] ? SubjectList.allId[sub].cname : '-'
    }

    created() {
        this.updateCount()
        this.getRecords()
        this.pollJobs()
        this.getSeries()

        const max = new Date().getFullYear() + 5
        for (let i = max; i >= 2017; i--) {
            this.dseOp.push(i)
        }

        for (let i = 8; i <= 11; i++) {
            this.pickerOptions.shortcuts!!.push({
                text: `${i}pm`,
                onClick(picker) {
                    const date: Date = picker.value as Date || new Date()
                    date.setHours(i + 12)
                    date.setMinutes(0)
                    date.setSeconds(0)
                    date.setMilliseconds(0)
                    picker.$emit('pick', undefined, true)
                        .$nextTick(() => {
                            picker.$emit('pick', date, true)
                        })
                }
            })
        }
    }

    getQ() {
        const q = {}
        if (this.form)
            q['form'] = this.form
        // if (this.dse)
        //     q['dse'] = this.dse
        if (this.dses.length)
            q['dses'] = this.dses
        if (this.subject)
            q['subject'] = this.subject

        switch (this.type) {
            case MessageSearchType.Course:
                if (this.course) {
                    q['type'] = this.type
                    q['course'] = this.course
                }
                break
            case MessageSearchType.Instructor:
                if (this.instructor) {
                    q['type'] = this.type
                    q['instructor'] = this.instructor
                }
                break
            case MessageSearchType.Series:
                if (this.series) {
                    q['type'] = this.type
                    q['series'] = this.series
                }
                break
            case MessageSearchType.Phone:
                if (this.phones) {
                    q['type'] = this.type
                    q['phones'] = this.phones.split('\n').map((p) => {
                        return p.replace(/ /g, '').trim()
                    }).filter(p => {
                        return p.length >= 8
                    })
                }
                break
        }
        return q
    }

    async updateCount() {
        const res = await createRequest('/bulk-messages/count', 'post', {}, this.getQ()).send()
        this.count = res.data.count
    }

    async getSeries() {
        const res = await createRequest('/courses/series', 'get').send()
        this.seriesList = res.data.series
    }

    fileUrlChanged() {
        if (this.fileUrl && !this.fileUrl.startsWith('http'))
            this.fileUrl = 'https://' + this.fileUrl
    }

    async send(test = false) {
        if (!this.message && !this.fileUrl)
            return

        if (test) {
            const body = {
                message: this.message,
                test: true
            }
            if (this.fileUrl)
                body['file_url'] = this.fileUrl
            await createRequest('/bulk-messages', 'post', {}, body).send()
            this.$message.info('Test Message Sent')
            return
        }

        this.sending = true
        const body = this.getQ()
        body['message'] = this.message
        if (this.fileUrl)
            body['file_url'] = this.fileUrl
        if (this.scheduled)
            body['scheduled'] = this.scheduled.getTime()
        await createRequest('/bulk-messages', 'post', {}, body).send()
        this.dialogVisible = false
        this.sending = false
        this.sendConfirmation = false
        await this.update()
    }

    async update() {
        await this.getRecords()
        await this.getJobs()
    }

    async getRecords(page = 1) {
        const q = {
            limit: this.step,
            skip: (page - 1) * this.step
        }
        const res = await createRequest('/bulk-messages/records', 'get', q).send()
        this.records = res.data.records
        this.totalCount = res.data.count
    }

    async pollJobs() {
        this.intervalPoll = setInterval(this.getJobs, 5000)
    }

    destroyed() {
        clearInterval(this.intervalPoll)
    }

    async getJobs() {
        const oldRid = new Set<string>()
        for (const j of this.jobs)
            oldRid.add(j.record_id)

        const res = await createRequest('/bulk-messages/jobs', 'get').setCache(this.cacheData).send()
        this.jobs = res.data.jobs

        const newRid = new Set<string>()
        for (const j of this.jobs)
            newRid.add(j.record_id)

        for (const oldR of Array.from(oldRid)) {
            if (!newRid.has(oldR)) {
                await this.getRecords(this.currentPage)
                break
            }
        }
    }

    deleteJobs(record: MessageRecord) {
        this.$confirm('Sure to delete message jobs?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then(async () => {
            await createRequest(`/bulk-messages/${record._id}`, 'delete').send()
            this.$message({
                type: 'success',
                message: 'Delete completed'
            })
            await this.update()
        }).catch(() => {
            this.$message({
                type: 'info',
                message: 'Delete canceled'
            })
        })
    }

    async uploadFile() {
        const e: HTMLInputElement = this.$refs.fileinput as HTMLInputElement
        e.click()
    }

    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        let uploadUrl = ''
        const baseUrl = getServerBaseURL()
        if (baseUrl.startsWith('http://') || baseUrl.startsWith('https://')) {
            uploadUrl = `${baseUrl}file`
        } else {
            uploadUrl = `https://${baseUrl}file`
        }

        let form = new FormData()
        form.append('file', e.target['files'][0])

        const loading = this.$loading({
            target: '.el-dialog',
            text: 'Uploading'
        })

        try {
            const token = await this.$auth.getAccessToken()

            const res = await axios({
                method: 'post',
                url: uploadUrl,
                data: form,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })

            this.fileUrl = res.data['file_url']
        } catch (e) {
            console.error(e)
            this.$message.error('Error')
        }

        loading.close()
    }

    showPhone(record) {
        this.phonePreview = record.options.phones.join('<br>')
        this.phonePreviewDialog = true
    }

    async confirmReboot() {
        this.$confirm('Sure to reboot ChatAPI instance?').then(async () => {
            await createRequest('/chat-api/reboot', 'post').send()
            this.$message.info('Request sent')
        }).catch()
    }

    async go() {
        await toAdminv('/whatsapp/records')
    }
}

